import api from "api";
import React, { useEffect, useState } from "react";
import { LeadType, RegionType } from "types";
import "./contact-us.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs() {
    const [brandName, setBrandName] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [surName, setSurName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [CAPTCHA, setCAPTCHA] = useState<boolean>(false);
    const [region, setRegion] = useState<RegionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<number>(30791);
    const [contactNumber, setContactNumber] = useState<string>("");
    const [purpose, setPurpose] = useState<string>("");
    const [leadSourch, setLeadSourch] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const getRegions = async () => {
        var regions = await api.region.getRegionAll();
        console.log(regions);

        setRegion(regions);
    };

    const getSelectedRegion = () => {
        var countryName = "";
        region.map((e: any) => {
            if (e.Id === selectedRegion) {
                countryName = e.Name;
            }
        });
        return countryName;
    };

    const changeRegion = async (Id: number) => {
        console.log(Id);

        setSelectedRegion(Id);
    };

    const insertLead = () => {
        var selectedRegionObj = getSelectedRegion();
        console.log(selectedRegionObj);

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(email)) {
            console.log("NOPE");
            toast.warning("Your email address is not valid", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        if (CAPTCHA != true) {
            toast.warning("Please check the checkbox 'I'm not a robot'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        var selectedRegionObj = getSelectedRegion();

        var lead = {
            Id: 0,
            EncryptId: "",
            EmailAddress: email,
            FirstName: name,
            LastName: surName,
            Company: brandName,
            Message: "The region is: " + selectedRegionObj,
            EmailProvider: "",
            ContactNumber: number,
            DateSubmitted: new Date(),
            IsDeleted: false,
            LeadTypeId: 1,
            ClientMessage: message,
            LeadSource: leadSourch,
            Purpose: purpose,
        } as LeadType;
        var ro = api.lead.insert(lead);

        toast.success("Your request was sent successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        getRegions();
    }, []);

    return (
        <div id="contact-us">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="container">
                <h2>Contact Us Today!</h2>
                <p>For any clarifications or further information, please complete the form below. Our team at amaSocial is ready to assist you!</p>
                <form>
                    <div className="form-item">
                        <input type="text" id="brand-name" value={brandName} onChange={(e) => setBrandName(e.currentTarget.value)} placeholder="Your company name / the brand:" />
                    </div>
                    <div className="form-item">
                        <input type="text" id="name" value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Your name:" />
                    </div>
                    <div className="form-item">
                        <input type="text" id="surname" value={surName} onChange={(e) => setSurName(e.currentTarget.value)} placeholder="Your surname:" />
                    </div>
                    <div className="form-item">
                        <input type="text" id="email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder="Email address:" />
                    </div>
                    <div className="form-item">
                        <input type="text" id="contact" value={contactNumber} onChange={(e) => setContactNumber(e.currentTarget.value)} placeholder="Contact number:" />
                    </div>
                    <select name="region" onChange={(e) => changeRegion(parseInt(e.currentTarget.value))}>
                        {region.map((e) => (
                            <option value={e.Id} selected={selectedRegion === e.Id ? true : false} key={e.Id}>
                                {e.Name}
                            </option>
                        ))}
                    </select>
                    <select name="purpose" onChange={(e) => setPurpose(e.currentTarget.value)}>
                        <option value="0" selected>
                            Purpose
                        </option>
                        <option value="Sales Enquiry">Sales Enquiry</option>
                        <option value="Request for Quotation">Request for Quotation</option>
                        <option value="Career-related Enquiry">Career-related Enquiry</option>
                    </select>

                    <div className="form-item">
                        <select name="leadSourch" onChange={(e) => setLeadSourch(e.currentTarget.value)}>
                            <option value="0" selected disabled>
                                How did you hear about amaSocial?
                            </option>
                            <option value="From a friend">From a friend</option>
                            <option value="From a client">From a client</option>
                            <option value="Google search">Google search</option>
                            <option value="Social media">Social media</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="form-item">
                        <input type="text" id="message" value={message} onChange={(e) => setMessage(e.currentTarget.value)} placeholder="Your Message" />
                    </div>

                    <div className="form-item">
                        <div className="button" onClick={() => insertLead()}>
                            submit
                        </div>
                    </div>
                    <div className="captcha-center">
                        <ReCAPTCHA sitekey="6LeZVA8gAAAAAETKb9Ub4UcWXtoiP3DuzTcVXUmN" onChange={() => setCAPTCHA(!CAPTCHA)} />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactUs;
