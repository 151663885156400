import { apiGet } from ".";

const Faq = {
    getAllFaq: async () => {
        const data = await apiGet(`${process.env.REACT_APP_CORPORATE_API_URI}/api/faq/all`);
        return data;
    },
};

export default Faq;
