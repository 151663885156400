import React from "react";
import "./why-amasocial.scss";
import amaSocialIcon from "../../assets/amaSocial_Logo_Icon.svg";

function WhyAmasocial() {
    return (
        <div id="why-amasocial">
            <div className="why-amasocial-content">
                <div>
                    <h2>Choose amaSocial</h2>
                    <p>
                        In the ever-expanding social media space, amaSocial stands out for a multitude of reasons. We are a direct-to-customer social media monitoring solution, ensuring that we retrieve your data directly from each
                        platform.
                    </p>
                    <br />
                    <p>Our acquisition systems, web-facing interface and data management capabilities set us apart. By solving problems independently, we deliver accurate and timely insights.</p>
                    <span className="d-block-m mt">
                        <br />
                        <h2>What makes us different…</h2>
                        <p>
                            We are a direct to customer social media monitoring business solution… we fetch all your data directly from each social platform ourselves, so we built our very own acquisition systems, then we allocate each
                            correct post to your project bin and to any child bins. The web-facing and data management stuff is ours too… now, as we are free of dependencies, the problems are ours to solve, so we take the full-on hit
                        </p>
                    </span>
                    <br />
                    <br />
                </div>
            </div>
            <div className="content-grid d-block-d">
                <div className="img-section">
                    <div className="img-container">
                        <img src={amaSocialIcon} alt="amaSocial logo icon" />{" "}
                    </div>
                </div>
                <div className="content-section">
                    <div>
                        <h2>Our mission is innovation and client satisfaction</h2>
                        <p>
                            At amaSocial, our mission is to provide innovative and customised brand tracking solutions that align with our clients’ unique requirements. We strive to be a technologically driven and dynamic team, employing
                            and developing top-quality professionals.
                        </p>
                        <br />
                        <p>With a focus on performance, accountability, measurability, responsiveness, creativity and success, we are dedicated to surpassing your expectations.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyAmasocial;
