import React, { useEffect, useState } from "react";
import { BlogType } from "types";
import "../blog.scss";
import moment, { Moment } from "moment";
import placeholderImgMin from "../../../assets/placeholderImg-min.png";

interface props {
    Blog: BlogType;
    TextPosition: string;
}

function BlogSort({ Blog, TextPosition }: props) {
    const [img, setImg] = useState<string>();

    const fetchImage = async () => {
        const res = await fetch(process.env.REACT_APP_CORPORATE_API_URI + "/api/blog/" + Blog.UniqueURL + ".jpg");
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImg(imageObjectURL);
    };

    useEffect(() => {
        fetchImage();
    }, []);
    return (
        <article className="blog-Item">
            <div className={"blog-" + TextPosition}>
                <div className="img-container">
                    {img && <img src={img} alt={Blog.UniqueURL} />}
                    {!img && <div className="img-fake"></div>}
                </div>
                <div className="content">
                    <span className="date">{moment(Blog.DateDisplay).format("MMM DD, yyyy")}</span>
                    <h2>{Blog.HeadLine}</h2>
                    <p dangerouslySetInnerHTML={{ __html: Blog.Synopsis }} />
                    <a href={"/blog/" + Blog.Id + "/" + Blog.UniqueURL} target="">
                        Read more
                    </a>
                </div>
            </div>
        </article>
    );
}

export default BlogSort;
