import React, { useState } from "react";
import "./product-section.scss";

function ProductSection() {
    const [selectedProducSection, setSelectedProducSection] = useState<number>(1);
    const [displayKeywordSection, setDisplayKeywordSection] = useState<string>("block");
    const [displayContentVarification, setDisplayContentVarification] = useState<string>("none");

    const changeActiveProductSection = (section: string) => {
        if ("keyword" === section) {
            setSelectedProducSection(1);
            setDisplayKeywordSection("block");
            setDisplayContentVarification("none");
        } else {
            setSelectedProducSection(2);
            setDisplayKeywordSection("none");
            setDisplayContentVarification("block");
        }
    };

    return (
        <div id="product-section">
            <div>
                <h2 className="center">So, this is what we have...</h2>
                <ul className="level-1">
                    <li>
                        <div className="header-switch">
                            <h2 className={selectedProducSection === 1 ? "selected-switch" : ""} onClick={() => changeActiveProductSection("keyword")}>
                                Brand Tracking
                            </h2>
                            <h2 className={selectedProducSection === 2 ? "selected-switch" : ""} onClick={() => changeActiveProductSection("content")}>
                                Content Verification
                            </h2>
                        </div>
                        <div className="scroll-container">
                            <ol className="level-2">
                                {selectedProducSection === 1 ? (
                                    <>
                                        <li className="card">
                                            <h3>
                                                Brand tracking: <br />
                                                <span className="second-heading">Amplify your reach</span>
                                            </h3>
                                            <div className="content">
                                                <p>
                                                    By sending your brand keywords into the social space, we retrieve the relevant posts and deliver them to you promptly. Our aim is to provide you with actionable insights that help you
                                                    enhance your brand's visibility and engagement.
                                                </p>
                                                <p className="price">
                                                    Guesstimate <br />
                                                    <span>R 2 944,00. </span>p/m
                                                </p>
                                            </div>
                                        </li>
                                        <li className="card">
                                            <h3>
                                                Competitor benchmarking: <br />
                                                <span className="second-heading">Stay ahead of the game</span>
                                            </h3>
                                            <p>
                                                Social media coverage is a numbers game, and in your industry, brands compete for their share of the spotlight. With amaSocial, you can gain a competitive edge through smart filtering and
                                                in-depth analysis.
                                            </p>
                                            <br />
                                            <p>We organise and compare data, allowing you to benchmark your performance against your competitors effectively.</p>
                                            <p className="price">
                                                Guesstimate <br /> <span>R 4 634,50. </span>p/m
                                            </p>
                                        </li>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {selectedProducSection === 2 ? (
                                    <>
                                        <li className="card">
                                            <h3>Content verification</h3>
                                            <p>
                                                This is about you and your stuff, all the content you are spilling into the different platforms... when should the posts go out, are they getting consumed, are they gaining followers. The
                                                bottom line is… are you generating leads to sales conversions
                                            </p>
                                            <p className="price">
                                                Guesstimate <br /> <span>R 2 921,00. </span>p/m
                                            </p>
                                        </li>
                                        <li className="card">
                                            <h3>
                                                Influencer partnerships: <br />
                                                <span className="second-heading">Unleash your brand's potential</span>
                                            </h3>
                                            <p>Partnering with influencers can significantly boost your brand's awareness and expand your audience reach. However, it's crucial to understand the intricacies involved.</p>
                                            <br />
                                            <p>
                                                Before embarking on an influencer collaboration, it's essential to know what to expect and how to maximise the benefits. amaSocial will provide you with the relevant information to learn more
                                                about influencer partnerships and what to keep in mind when utilising them.
                                            </p>
                                            <p className="price">
                                                Guesstimate <br /> <span>R 2 748,50. </span>p/m
                                            </p>
                                        </li>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </ol>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ProductSection;
