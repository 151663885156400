import React, { useEffect, useState } from "react";
import api from "../../../api";
import { BlogType } from "../../../types";
import BlogSort from "../blog-short/blog-sort";
import "./blog-section.scss";

function BlogSection() {
    const [blogs, setBlogs] = useState<BlogType[]>([]);

    const getTopBlogs = async () => {
        var blogs = await api.blog.getBlogsTakeSkip(2, 0);
        console.log(blogs);

        setBlogs(blogs);
    };

    useEffect(() => {
        getTopBlogs();
    }, []);

    return (
        <div id="blog-section">
            <div className="blog-grid">
                {blogs.map((blog) => (
                    <BlogSort Blog={blog} TextPosition="bottom" />
                ))}
            </div>
        </div>
    );
}

export default BlogSection;
