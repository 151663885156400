import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Home, BlogPage, Faq, Blog } from "components";
import ReactGA from "react-ga";
import Add from "components/add/add";

function App() {
    ReactGA.initialize("G-NE0TSWX2T6");
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/blog/:Id/:UniqueURL" element={<Blog />} />
                <Route path="/blog/:UniqueURL" element={<Blog />} />
                <Route path="/add/:source" element={<Add />} />
            </Routes>
        </Router>
    );
}

export default App;
