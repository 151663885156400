import React from "react";
import "./stats-section.scss";
import CheckMark_Icon from "../../assets/stats-icons/CheckMark_Icon.svg";
import Clients_Icon from "../../assets/stats-icons/Clients_Icon.svg";
import ClipBoard_Icon from "../../assets/stats-icons/ClipBoard_Icon.svg";

function StatsSection() {
    return (
        <div id="states-section">
            <div className="heading-secrion">
                <h2>Experience our expertise</h2>
                <p>
                    With over 1 000 active projects and a client base of over 300 satisfied customers, amaSocial has established itself as a trusted industry leader. Our team comprises skilled professionals in PR, communications and
                    marketing — consistently striving to achieve excellence.
                </p>
                <br />
                <p>
                    We hope to not only provide you with valuable insights into the social media space but also assist you in growing your online presence. As we continue to design more useful tools, we are committed to enhancing your
                    understanding of social media data.
                </p>
                <br />
            </div>

            <div className="stats">
                <div className="stat">
                    <img src={ClipBoard_Icon} alt="Clip board" />
                    <div>
                        <p className="header">1000 +</p>
                        <p className="description">Active Projects</p>
                    </div>
                </div>
                <div className="stat">
                    <img src={Clients_Icon} alt="Client icon" />
                    <div>
                        <p className="header">300 +</p>
                        <p className="description">Clients Satisfied</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatsSection;
