import { AddCountType } from "types/addcount";
import { apiPost } from ".";

const addCount = {
    countAdd: async (addCount: AddCountType) => {
        const data = await apiPost(`${process.env.REACT_APP_CORPORATE_API_URI}/api/addcount/count/add`, addCount);
        return data;
    },
};

export default addCount;
