import { apiGet } from ".";

const region = {
    getRegionAll: async () => {
        const data = await apiGet(`${process.env.REACT_APP_CORPORATE_API_URI}/api/region/country`);
        return data;
    },
};

export default region;
