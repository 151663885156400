import api from "api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlogType } from "types";
import moment, { Moment } from "moment";
import "./blog.scss";
import Footer from "components/footer";
import Nav from "components/nav";
import { Helmet } from "react-helmet";

function Blog() {
    const params: any = useParams();
    const [blog, setBlog] = useState<BlogType>({} as BlogType);
    const [img, setImg] = useState<string>();
    console.log(params.UniqueURL);

    const fetchImage = async () => {
        console.log(process.env.REACT_APP_CORPORATE_API_URI + "/api/blog/" + params.UniqueURL + ".jpg");

        const res = await fetch(process.env.REACT_APP_CORPORATE_API_URI + "/api/blog/" + blog.UniqueURL + ".jpg");
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImg(imageObjectURL);
    };

    const getBlog = async () => {
        debugger;
        if (!params.Id) {
            var blog = await api.blog.getBlogByUrl(params.UniqueURL);
            console.log(blog);
            setBlog(blog);
        } else {
            var blog = await api.blog.getById(Number(params.Id));
            setBlog(blog);
        }
    };

    useEffect(() => {
        getBlog();
    }, [params.UniqueURL, params.Id]);

    useEffect(() => {
        fetchImage();
    }, [blog]);

    const articleStructuredData = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        headline: blog.HeadLine,
        image: [process.env.REACT_APP_CORPORATE_API_URI + "/api/blog/" + blog.UniqueURL + ".jpg"],
        datePublished: blog.DateDisplay,
        dateModified: blog.DateDisplay,
        author: [
            {
                "@type": "Person",
                name: "amaSocial",
                url: "https://amaosical.co.za",
            },
        ],
    };

    return (
        <>
            <script type="application/ld+json">{JSON.stringify(articleStructuredData)}</script>
            <Helmet>
                <title>{"amaSocial | " + blog.HeadLine}</title>

                <meta name="description" content={blog.Synopsis} />
            </Helmet>

            <Nav selectedItem={2} />

            <div id="blog" className="container">
                <div className="spacer"></div>
                <div className="pure-g">
                    <span className="date">{moment(blog.DateDisplay).format("MMM DD, yyyy")}</span>
                    <h1>{blog.HeadLine}</h1>
                    {img && <img src={img} alt={blog.UniqueURL} />}
                    {!img && <div className="img-fake"></div>}
                    <div dangerouslySetInnerHTML={{ __html: blog.Body }} />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Blog;
