import React from "react";
import "./footer.scss";
import logo from "../../assets/amaSocial_Logo_Final.svg";

function Footer() {
    return (
        <div id="footer">
            <div className="grid-container">
                <div className="logo-section section">
                    <img src={logo} alt="amaSocial logo" />
                    <p>
                        AMASOCIAL (PTY) LTD <br />
                        Copyright @amaSocial {new Date().getFullYear()}
                    </p>
                    <p></p>
                </div>
                <div className="links-section section">
                    <h3>Links</h3>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/blogs">Blogs</a>
                        </li>
                        <li>
                            <a href="/#product-section">Services</a>
                        </li>
                        <li>
                            <a href="/">FAQ's</a>
                        </li>
                        <li>
                            <a href="/#contact-us">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div className="services-section section">
                    <h3>Services</h3>
                    <ul>
                        <li>
                            <a href="#product-section">Brand tracking</a>
                        </li>
                        <li>
                            <a href="#product-section">Competitor benchmarking</a>
                        </li>
                        <li>
                            <a href="#product-section">Industry topics</a>
                        </li>
                        <li>
                            <a href="#product-section">Content verification</a>
                        </li>
                        <li>
                            <a href="#product-section">Influencer partnerships</a>
                        </li>
                    </ul>
                </div>
                <div className="contact-section section">
                    <h3>Contact info</h3>
                    <ul>
                        <li>
                            <a href="https://goo.gl/maps/CrAAgD3dstbyf8r67" target="_blank">
                                010 020 5255 <br /> hello@amasocial.co.za <br /> 23 J.G Strydom Rd <br /> Roodepoort
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;
