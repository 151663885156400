import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <script src="https://unpkg.com/react/umd/react.production.min.js"></script>

        <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"></script>

        <script src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"></script>
        <link rel="stylesheet" type="text/css" href="path/to/notifications.css"></link>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap" rel="stylesheet"></link>
        <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer />

        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
