import React from "react";
import "./landing-page.scss";
import twitterIcon from "../../assets/social-icons/x-logo.svg";
import facebookIcon from "../../assets/social-icons/facebook.svg";
import instagramIcon from "../../assets/social-icons/instagram.svg";
import youtubeIcon from "../../assets/social-icons/youtube.svg";
import linkedIncon from "../../assets/social-icons/linkedin.svg";

function LandingPage() {
    return (
        <div id="landing-Page">
            <div className="wave-container">
                <div className="content-container">
                    <div className="header">
                        <h1>Discover the power of social media insights</h1>
                        <div className="header-content">
                            <p>At our core, amaSocial understands that knowing what you want before you even start searching is essential.</p>
                            <br />
                            <p>
                                The social media landscape is vast, with a multitude of voices expressing themselves freely and without regulations. People curse, criticise, complain, comment and promote their products and brands — and
                                that’s where we come in.
                            </p>
                            <br />
                            <p>Welcome to amaSocial, the leading media monitoring company that collects and analyses the right information about your brand, service and reputation.</p>
                        </div>
                    </div>
                    <div className="icon-section">
                        <div className="icon-collection">
                            <div className="row-1 row">
                                <img className="icon-1 x-logo" src={twitterIcon} alt="Twitter icon" />
                                <img className="icon-2" src={facebookIcon} alt="Facebook icon" />
                            </div>
                            <div className="row-2 row">
                                <img className="icon-3" src={instagramIcon} alt="Instagram icon" />
                                <img className="icon-4" src={youtubeIcon} alt="Youtube icon" />
                            </div>
                            <div className="row-3 row">
                                <img className="icon-5" src={linkedIncon} alt="LinkedIn icon" />
                            </div>
                            <p>“amaSocial teaches people a lot about the social media space”</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
