import React, { useState } from "react";
import { FaqType } from "types";
import "./faq-item.scss";

interface props {
    faq: FaqType;
}

function FaqItem({ faq }: props) {
    const [showContent, setShowContent] = useState<boolean>(false);

    return (
        <div className="faq-item">
            <div className="header-grid" onClick={() => setShowContent(!showContent)}>
                <h3>{faq.Title}</h3>
                <span>{showContent === true ? "-" : "+"}</span>
            </div>
            <p className={showContent === true ? "" : "d-none"} dangerouslySetInnerHTML={{ __html: faq.Description }} />
        </div>
    );
}

export default FaqItem;
