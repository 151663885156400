import { apiGet } from ".";

const blog = {
    getBlogsTakeSkip: async (take: number, skip: number) => {
        const data = await apiGet(`${process.env.REACT_APP_CORPORATE_API_URI}/api/blog/online/skip?take=${take}&skip=${skip}`);
        return data;
    },
    getBlogByUrl: async (uniqueURL: string) => {
        const data = await apiGet(`${process.env.REACT_APP_CORPORATE_API_URI}/api/blog/uniqueurl?uniqueurl=${uniqueURL}`);
        return data;
    },
    getById: async (Id: number) => {
        const data = await apiGet(`${process.env.REACT_APP_CORPORATE_API_URI}/api/blog/id?id=${Id}`);
        return data;
    },
};

export default blog;
