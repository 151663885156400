import React, { useState } from "react";
import "./Nav.scss";
import logo from "../../assets/amaSocial_Logo_Final.svg";
import burgerMenu from "../../assets/menu-icon.png";
import burgerMenu2 from "../../assets/menu-icon-black.svg";
import cart from "../../assets/cart-icon-w.svg";
import { Link } from "react-router-dom";

interface props {
    selectedItem: number;
}

function Nav({ selectedItem }: props) {
    const [showMenu, setShowMenu] = useState<boolean>(true);
    const [selectedPage, setSelectedPage] = useState<number>(selectedItem);

    const changeDir = (changeTo: string) => {
        if (window.location.pathname === changeTo) {
            return;
        }
        window.location.href = changeTo;
    };

    return (
        <>
            <div className="spacer"></div>
            <nav>
                <img src={logo} alt="amaSocial logo" />
                <div className="f-center">
                    <ul className={showMenu === true ? "display-none" : ""}>
                        <li className={selectedPage === 1 ? "selected" : ""} onClick={() => changeDir("/")}>
                            Home
                        </li>
                        <li className={selectedPage === 2 ? "selected" : ""} onClick={() => changeDir("/blogs")}>
                            Blogs
                        </li>
                        {/* PHASE 2 */}
                        <li className={selectedPage === 3 ? "selected" : ""} onClick={() => changeDir("/#product-section")}>
                            Services
                        </li>
                        <li className={selectedPage === 4 ? "selected" : ""} onClick={() => changeDir("/#states-section")}>
                            Our Goals
                        </li>
                        <li className={selectedPage === 5 ? "selected" : ""} onClick={() => changeDir("/faq")}>
                            FAQ's
                        </li>
                        <li className={selectedPage === 6 ? "selected" : ""} onClick={() => changeDir("/#contact-us")}>
                            Contact Us
                        </li>
                        <Link to={"/docs/HR-01-030 Rev 1 PAIA Manual_amaSocial.pdf"} target="_blank">
                            <li className={selectedPage === 6 ? "selected" : ""} onClick={() => changeDir("/#contact-us")}>
                                POPI – PAIA
                            </li>
                        </Link>
                        {/* PHASE 2 */}
                        {/* <li className={selectedPage === 7 ? "selected" : ""} onClick={() => setSelectedPage(7)}>
                            <div className="cart">
                                <img src={cart} alt="" />
                                <span>0</span>
                            </div>
                        </li> */}
                        <li className="desktop-hidden ">Login</li>
                    </ul>
                    <a className="navButton mobile-hidden login-btn" href="https://app.amasocial.co.za/login" target="blank">
                        Login
                    </a>
                    <img src={burgerMenu2} className="burgerMenu desktop-hidden" alt="burger menu" onClick={() => setShowMenu(!showMenu)} />
                </div>
            </nav>
        </>
    );
}

export default Nav;
