import { LeadType } from "types";
import { apiPost } from ".";

const lead = {
    insert: async (lead: LeadType) => {
        const data = await apiPost(`${process.env.REACT_APP_CORPORATE_API_URI}/api/lead/insert`, lead);
        return data;
    },
};

export default lead;
