import api from "api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AddCountType } from "types/addcount";

function Add() {
    const params: any = useParams();
    const countThisAdd = async () => {
        await api.addCount.countAdd({
            AddSource: params.source + "",
            DTUpdate: new Date().toJSON().split(".")[0] + "",
            Id: 0,
            IsDeleted: false,
            RedirectCount: 1,
            WebsiteUrl: "add/" + params.source,
        } as AddCountType);
        window.location.href = "/";
    };
    useEffect(() => {
        try {
            if (params.source) {
                countThisAdd();
            } else {
                window.location.href = "/";
            }
        } catch {
            window.location.href = "/";
        }
    }, []);
    return <div></div>;
}

export default Add;
