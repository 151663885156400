import api from "api";
import Footer from "components/footer";
import Nav from "components/nav";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { BlogType } from "types";
import BlogSort from "../blog-short/blog-sort";
import BlogSkeleton from "../blog-skeleton/blog-skeleton";
import "./blog-page.scss";

function BlogPage() {
    const [blogs, setBlogs] = useState<BlogType[] | null>(null);
    const [skip, setSkip] = useState<number>(0);
    const [take, setTake] = useState<number>(10);

    const getTopBlogs = async () => {
        var blogs = await api.blog.getBlogsTakeSkip(take, 0);
        console.log(blogs);

        setBlogs(blogs);
    };

    useEffect(() => {
        getTopBlogs();
    }, []);

    return (
        <div className="blog-section">
            <Helmet>
                <title>amaSocial | Blogs</title>
                <meta name="description" content={"amaSocial blogs page"} />
            </Helmet>
            <Nav selectedItem={2} />
            <div className="spacer"></div>
            <div className="header-container">
                <h2>Blogs</h2>
                <div className="blog-section">
                    <div className="blog-grid">
                        {/* <BlogSkeleton /> */}
                        {blogs && blogs.map((blog) => <BlogSort Blog={blog} TextPosition="bottom" />)}
                        <a className="load-more-"></a>
                    </div>
                </div>
            </div>
            {blogs && <Footer />}
        </div>
    );
}

export default BlogPage;
