import React, { useEffect } from "react";
import Nav from "../nav";
import LandingPage from "../landing-page";
import ProductSection from "../product-section";
import WhyAmasocial from "../why-amasocial";
import StatsSection from "../stats-section";
import BlogSection from "../blog/blog-section";
import OurIndustry from "components/our-industry";
import ContactUs from "components/contact-us";
import Footer from "components/footer";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../../global_Css/G_font.css";
import "./Home.scss";
function Home() {
    return (
        <div>
            {/* <script type="application/ld+json">{JSON.stringify(structuredData)}</script> */}
            <Helmet>
                <title>amaSocial | Social media monitoring</title>
                <meta name="description" content={"Social media monitoring"} />
            </Helmet>
            <Nav selectedItem={1} />
            <LandingPage />
            <ProductSection />
            <BlogSection />
            <WhyAmasocial />
            <StatsSection />
            {/* <OurIndustry /> */}
            <ContactUs />
            <Footer />

            {/* Same as */}
            <ToastContainer />
        </div>
    );
}

export default Home;
