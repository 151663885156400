import Nav from "components/nav";
import React, { useEffect, useState } from "react";
import "./faq.scss";
import { FaqType } from "types";
import api from "api";
import FaqItem from "./faq-item";
import { Helmet } from "react-helmet";

function Faq() {
    const [faqs, setFaqs] = useState<FaqType[]>([]);
    const [showLines, setShowLines] = useState<number[]>([1]);

    const getFaqs = async () => {
        var faqs = await api.faq.getAllFaq();
        setFaqs(faqs);
    };

    var counter = 1;

    useEffect(() => {
        getFaqs();
    }, []);

    return (
        <div id="faq">
            <Helmet>
                <title>amaSocial | FAQ</title>
                <meta name="description" content={"This is the frequently asked question here at amaSocial"} />
            </Helmet>
            <Nav selectedItem={5} />
            <div className="spacer"></div>
            <div className="faq-container">
                <div className="faq-grid">
                    {faqs.map((x, i) => (
                        <FaqItem key={i} faq={x} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Faq;
